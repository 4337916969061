import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"

// import Hero from "../components/hero"
import Header from "./header"
import Footer from "./footer"
import "../styles/custom.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {/* <Hero /> */}
      <Container className="text-center" id="main-container">
        <Row className="mt-5">
          <Col>
            <main>{children}</main>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
