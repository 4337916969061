import React from "react"
import PropTypes from "prop-types"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import { FiFacebook, FiInstagram } from "react-icons/fi"
import fullLogo from "../images/full-logo.png"
import Image from "react-bootstrap/Image"


const Header = () => {

  return (
    <header>
      <Navbar bg="light" expand="md" className="mt-2 mb-n4">
        <Container>
          <Navbar.Brand href="/">
            <Image
              fluid
              src={fullLogo}
              width="100%"
              className="d-inline-block align-top"
              id="logo"
              alt="Star Caps Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/products" >BROWSE PRODUCTS</Nav.Link>
              <Nav.Link href="/about" >ABOUT US</Nav.Link>
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link href="https://www.facebook.com/pages/category/Clothing-Store/Star-Caps-647958985217907/" target="_blank" rel="noopener noreferrer"><FiFacebook size={24}/></Nav.Link>
              <Nav.Link href="https://instagram.com/starcapsmakeyourdesign?igshid=1vzswuiu5yp7l" target="_blank" rel="noopener noreferrer"><FiInstagram size={24}/></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
