import React from "react"
import { Container, Col, Row } from "react-bootstrap"

const Footer = () => {
    return (
        <footer className="pt-5 pb-3">
            <Container>
                <Row>
                    <Col className="text-center">
                        © {new Date().getFullYear()} Star Caps | Phone: <a href="tel:832-630-0427" rel="nofollow">832-630-0427</a> or <a href="tel:713-532-2177" rel="nofollow">713-532-2177</a>  | Fax: 713-532-2179 | Email: <a href="mailto:starcapshouston@gmail.com">starcapshouston@gmail.com</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer